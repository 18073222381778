.Button-Wrapper {
    padding-top: 5px;
    margin-top: 5px;
    min-width: min(500px, 96vw);
}

.Button-Wrapper > button {
    box-sizing: border-box;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    background-color: #ff6600;
    color: #fff;
    font-size: 16px;
    cursor: pointer;
    outline: none;
    transition: background-color 0.3s ease;
}


/*.Button-Scheme-Main > button {*/
/*    background-color: #2f55bd;*/
/*    cursor: pointer;*/
/*}*/

.Button-Scheme-Main > button:hover{
    background-color: #bd4c02; /* Darker shade of primary color on hover */
}

.Button-Scheme-Main > button:focus {
    box-shadow: 0 0 0 3px rgba(189, 76, 2, 0.4); /* Add a focus outline */
}

/* Disabled Styles */
.Button-Disabled > button{
    opacity: 0.5; /* Reduce opacity for disabled state */
    cursor: not-allowed; /* Change cursor to not-allowed for disabled state */
}

/*.Button-Scheme-Danger > button {*/
/*    background-color: #ff0000;*/
/*}*/

/*.Button-Scheme-Danger > button:hover{*/
/*    background-color: #fc4b4b;*/
/*}*/
