.Gallery-Strip {
    padding-top: 15px;
    width: 100%;
    background-color: #f5f5f5;
}

.Gallery-Title {
    width: 100%;
    display: flex;
    padding-bottom: 5px;
    flex-direction: column; /* Set flex-direction to column */
    justify-content: center; /* Center vertically */
    align-items: center; /* Center horizontally */
    font-size: 5vw;
    color: #001f3f;
}


.Gallery-Grid {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center; /* Center vertically */
    align-items: center; /* Center horizontally */
}

.Gallery-Grid-Entry {
    /*padding-left: 10px;*/
    /*padding-right: 10px;*/
    position: relative;
    overflow: hidden;
    transition: all 0.3s ease;
}


.Gallery-Grid-Entry:hover .Gallery-Image-Wrapper {
    transform: scale(1.1);
}


.Gallery-Image-Wrapper {
    width: 20vw;
    height: 30vh;
    min-width: 300px;
    min-height: 200px;
    position: relative;
    background-size: cover;
    transition: transform 0.3s ease;
    background-repeat: no-repeat;
}


.overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.5);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
    opacity: 0;
    transition: opacity 0.3s ease;
    cursor: pointer;
}

.Gallery-Grid-Entry:hover .overlay {
    opacity: 1;
}

.overlay-content {
    width: 100%;
    display: flex;
    justify-content: center;
    align-self: flex-end; /* Snap to the bottom of the parent */
}

.overlay-content > div {
    width: 30%;
    text-align: center;
    font-size: max(1vw, 18px);
    text-shadow: -1px 0 white, 0 1px white, 1px 0 white, 0 -1px white;
    font-weight: bold;
    margin-left: 5px;
    margin-right: 5px;
    padding: 8px 16px 30px; /* Add padding for button-like appearance */
    border: 1px solid #000000; /* Border for the button */
    border-radius: 10px 10px 0 0; /* Rounded corners, only on the top */
}
.overlay-content > div:first-of-type {
    background-color: rgba(255,255,255,0.5); /* Background color for the button */
}


.overlay-button-sold {
    background-color: #cccccc; /* Background color for the button */
}

.overlay-button-available:hover {
    transform: scale(1.1);
    cursor: pointer;
}

.overlay-button-available {
    background-color: #ff6600; /* Background color for the button */
}
.overlay-button-soon {
    background-color: #ff6666; /* Background color for the button */

}

.modal {
    display: flex;
    position: fixed;
    z-index: 999;
    top: 0;
    left: 0;
    width: calc(100% - 20px);
    margin: 10px;
    height: calc(100% - 20px);
    border-radius: 40px;
    background-color: rgba(0, 0, 0, 0.95); /* Semi-transparent black overlay */
    color: white;
    align-items: center;
    justify-content: center;
    overflow-y: hidden; /* Enable vertical scrolling within modal */
}

.modal-content {
    padding: 20px;
    border-radius: 5px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3); /* Box shadow for depth */
    width: 100%; /* Take full width of modal */
    height: 100%;
    max-height: 100%; /* Take full height of modal */
    overflow-y: auto; /* Enable vertical scrolling if content exceeds height */
}

.close {
    position: absolute;
    top: 10px;
    right: 30px;
    font-size: max(5vw, 20px);
    cursor: pointer;
    color: #666;
    margin-top: min(-1vw, -4px);
}

.Modal-Data {
    height: 100%;
}

.Gallery-Image-Wrapper-Full{
    width: 50%;
    height: 80%;
    margin-top: 5%;
    margin-bottom: 5%;
    margin-left: 5%;
    /*min-width: 300px;*/
    min-height: 200px;
    position: absolute;
    top: 0px;
    left: 0px;
    background-size: contain;
    background-repeat: no-repeat;
}

.Gallery-Modal-Data-Wrapper {
    width: 50%;
    height: 80%;
    margin-top: 5%;
    margin-bottom: 5%;
    margin-right: 5%;
    /*min-width: 300px;*/
    min-height: 200px;
    position: absolute;
    top: 0px;
    right: 0px;

    padding-top: 10%;
}

.Gallery-Modal-Data-Title {
    font-size: max(2vw, 20px);
    padding-bottom: 20px;
}
.Gallery-Modal-Data-Description {
    font-size: max(1vw, 16px);
    padding-bottom: 20px;
}
.Gallery-Modal-Data-Dimensions {
    font-size: max(1vw, 16px);
    padding-bottom: 20px;
}

.Gallery-Modal-Data-Button-Wrapper {
    width: 40%;

}