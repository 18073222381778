@import url('https://fonts.googleapis.com/css2?family=Amatic+SC:wght@700&display=swap');

.dark-theme {
    --colour-primary: #cd1d1d;
    --colour-primary-rgb: 205, 29, 29;
    --colour-round-background-rgb: #ffffff;
    --colour-round-background-rgb: 255,255,255;
    --colour-secondary: #0f0f43;
    --colour-secondary-rgb: 15, 15, 67;
    --colour-neutral: #ffffff;
    --colour-blank: #000000;
    --colour-blank-rgb: 0, 0, 0;
    --colour-confirm: #00ff00;
    --colour-confirm-rgb: 0, 255, 0;
    --colour-progressbar: #e78c50;
    --colour-progressbar-rgb: 231, 140, 80;
}

.App {
    --header-font: 'Amatic SC', cursive;
    --popout-color: var(--colour-primary);
    --popout-text-color: var(--colour-neutral);
    --secondary-text-color: var(--colour-secondary);
    --secondary-color: var(--colour-neutral);
    --main-color: var(--colour-secondary);
    --main-text-color: var(--colour-neutral);
    --max-page-width: 100vw;
    --page-width: calc(min(100vw, var(--max-page-width)));
    --history-width: calc(2/3*var(--page-width) - 20px);
    height:100%;
    max-width: var(--page-width);
    margin-bottom: 50px;
}
.App-wrapper {
    background-color: #f5f5f5;
    min-width: 100vw;
    min-height: 100%;
    height: auto;
    position: absolute;
    z-index: -300;
}


#root, .bodyWrapper, body {
    overflow-x: hidden;
    width: 100vw;
}

.rcc-decline-button,
#rcc-decline-button,
.rcc-confirm-button,
#rcc-confirm-button {
    box-sizing: border-box !important;
    padding: 10px 20px !important;
    border: none !important;
    border-radius: 5px !important;
    font-size: 16px !important;
    cursor: pointer !important;
    outline: none !important;
    transition: background-color 0.3s ease !important;
}

/* Decline Button Styles */
.rcc-decline-button,
#rcc-decline-button {
    background-color: #dc3545 !important; /* Red color */
    color: #fff !important;
}

/* Confirm Button Styles */
.rcc-confirm-button,
#rcc-confirm-button {
    background-color: #007bff !important;
    color: #fff !important;
}

/* Hover Styles */
.rcc-decline-button:hover,
#rcc-decline-button:hover,
.rcc-confirm-button:hover,
#rcc-confirm-button:hover {
    background-color: #9c2e3e !important; /* Darker shade of red for decline button */
}

/* Focus Styles */
.rcc-decline-button:focus,
#rcc-decline-button:focus,
.rcc-confirm-button:focus,
#rcc-confirm-button:focus {
    box-shadow: 0 0 0 3px rgba(220, 53, 69, 0.4) !important; /* Red focus outline for decline button */
    /* Same as the modern button for confirm button */
}

.CookieConsent {
    background: lightblue !important;
    color: black !important;
}