.WelcomeBanner {
    width: 100%;
    display: flex;
    min-height: 100vh;
    flex-direction: column; /* Set flex-direction to column */
    justify-content: center; /* Center vertically */
    align-items: center; /* Center horizontally */
    height: 100%; /* Set height to 100% to span the full height */
    background-color: #0d3e72;
    overflow: hidden;
}

.WelcomeBannerTitle {
    font-size: 8vw;
    color: #f5f5f5;
    z-index: 2;
}


.WelcomeBannerBubbles {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 100%;
    background: radial-gradient(circle, rgba(16, 153, 248, 0.5) 10%, transparent 20%);
    background-size: 400% 400%;
    z-index: 0;
    animation: bubbles 5s infinite alternate;
}

.WelcomeBannerBubble {
    position: absolute;
    background-color: rgba(255, 255, 255, 0.5); /* Semi-transparent white */
    border-radius: 50%; /* Circular shape */
    animation: rise 5s infinite;
}

@keyframes rise {
    0% {
        transform: translateY(0) scale(1);
        opacity: 1;
    }
    100% {
        transform: translateY(-10000%) scale(0.2); /* Move up and scale down */
        opacity: 0; /* Fade out */
    }
}

.WelcomeBannerLogo {
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.WelcomeBannerLogo svg {
    width: 100%;
    height: 100%;
    filter: drop-shadow(0px 0px 10px rgba(255, 255, 255, 0.8)); /* Embossing effect */
    opacity: 0.3;
}