.ContactStrip {
    padding-top: 15px;
    background-color: #f5f5f5;
    max-width: 100%;
}

.ContactTitle {
    padding-bottom: 5px;
    color: #001f3f;
    width: 100%;
    display: flex;
    flex-direction: column; /* Set flex-direction to column */
    justify-content: center; /* Center vertically */
    align-items: center; /* Center horizontally */
    font-size: 5vw;
}

.ContactSectionConfirmMessage,
.ContactSectionCaptchaWrapper,
.ContactForm {
    width: min(100vw, 100%);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-bottom: 10px;
}

.ContactFormEntry {
    min-width: min(500px, 96vw);
    margin-bottom: 10px;
}

textarea,
input[type="text"] {
    box-sizing: border-box;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: max(1vw, 16px);
    outline: none; /* Remove outline on focus */
    transition: border-color 0.3s ease;
}

/* Focus Styles */
textarea:focus,
input[type="text"]:focus {
    border-color: #bd4c02; /* Change border color on focus */
}

/* Placeholder Styles */
textarea,
input[type="text"]::placeholder {
    color: #aaa; /* Placeholder text color */
}

.ContactSectionCaptchaText {
    padding-top: 10px;
    font-size: max(1vw, 16px);
}

.ContactSectionConfirmMessage {
    font-size: max(1vw, 16px);
}